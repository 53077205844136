import IconBedSVG from '@assets/icon-bed.svg';
import { SVG_SIZES } from '@utils/constants';
import classNames from 'classnames';
import styles from './css';

type IconProps = {
  monochrome?: boolean;
  size?: 'xs' | 'sm' | 'md' | 'lg';
  strokeColor?: 'black700' | 'black600';
  withFill?: boolean;
};

const IconBed = ({
  monochrome = true,
  size = 'md',
  strokeColor = 'black700',
  withFill = true,
}: IconProps) => {
  const style = {
    height: SVG_SIZES[size],
    width: SVG_SIZES[size],
  };

  return (
    <>
      <IconBedSVG
        className={classNames({
          monochrome: monochrome && withFill,
          [`stroke-${strokeColor}`]: strokeColor,
          'no-fill': !withFill,
        })}
        style={style}
      />
      <style jsx={true}>{styles}</style>
    </>
  );
};

export default IconBed;
