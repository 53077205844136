import IconImage from '@assets/icon-image.svg';
import {
  TypoSBold,
  TypoXS,
  TypoXSBold,
  TypoXXS,
  TypoXXSBold,
  TypoXXXSBold,
} from '@atoms/Typos';
import Link from '@components/Link';
import ProfileIcon from '@molecules/ProfileIcon';
import { TravelStoryListItem } from '@services/hafh/types/generated';
import IconBed from '@svg-icons/IconBed';
import routes, { route } from '@utils/routes';
import classNames from 'classnames';
import Image from 'next/image';
import styles from './css';

const TravelStoryCard = ({
  isListView = false,
  travelStory,
}: {
  isListView?: boolean;
  travelStory: TravelStoryListItem;
}) => {
  if (!travelStory) {
    return null;
  }

  return (
    <Link
      href={route(routes.travelStoryDetail, {
        travelStoryId: travelStory.id,
      })}
    >
      <div
        className={classNames('travel-story-card', {
          'list-view': isListView,
        })}
      >
        <div className="card-thumbnail">
          <div className="card-thumbnail-image-cont">
            <Image
              alt={travelStory.title || 'travel-story-thumbnail'}
              className="banner-slider-image"
              fill={true}
              src={travelStory.thumbnail_url}
              style={{
                objectFit: 'cover',
              }}
            />
            <div className="thumbnail-image-overlay" />
            <div className="property-prefecture">
              <TypoXXSBold
                color="white"
                text={travelStory.stays[0].property.prefecture}
              />
            </div>
            <div className="thumbnail-info">
              {isListView ? (
                <TypoSBold color="white" text={travelStory.title || ''} />
              ) : (
                <TypoXSBold color="white" text={travelStory.title || ''} />
              )}
              <div className="row">
                <div className="author-info">
                  <ProfileIcon neighbor={travelStory.neighbor} size="sm" />
                  <TypoXXSBold
                    color="white"
                    text={travelStory.neighbor?.nickname || ''}
                  />
                </div>
                <div className="image-info">
                  <IconImage />
                  <TypoXXXSBold
                    color="white"
                    text={travelStory.total_images_count || ''}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="story-info">
            <div className="desc">
              <TypoXS
                color="black"
                text={travelStory.stays[0].description || ''}
              />
            </div>
            <div className="property-name">
              <IconBed size="xs" strokeColor="black600" withFill={false} />
              <TypoXXS
                color="black600"
                text={travelStory.stays[0].property.name || ''}
              />
            </div>
          </div>
        </div>
      </div>
      <style jsx={true}>{styles}</style>
    </Link>
  );
};

export default TravelStoryCard;
