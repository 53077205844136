import COLORS from '@utils/colors';
import { BREAKPOINT } from '@utils/constants';
import css from 'styled-jsx/css';

const { desktop } = BREAKPOINT;

const styles = css`
  .travel-story-slider {
    padding-top: 24px;
    @media screen and (min-width: ${desktop}px) {
      padding-top: 40px;
    }
    border-bottom: 1px solid ${COLORS.black5};
    .travel-story-slider-title-cont {
      margin: 0 16px 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .travel-story-slider-inner {
      max-width: 1280px;
      margin-right: auto;
      margin-left: auto;
      :global(.swiper) {
        padding-bottom: 8px;
        :global(.swiper-button-prev),
        :global(.swiper-button-next) {
          position: absolute;
          top: calc(50% - 24px);
          width: 40px;
          height: 40px;
          filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
          transition: all 0.3s;
          opacity: 0.35;
          &:hover {
            opacity: 1;
          }
        }
        :global(.swiper-button-disabled) {
          display: none;
        }
        :global(.swiper-button-next) {
          right: 32px;
        }
        :global(.swiper-button-prev) {
          left: 32px;
          @media (max-width: ${BREAKPOINT.desktop}px) {
            display: none;
          }
        }
      }
    }
  }
`;

export default styles;
