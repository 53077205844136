import React from 'react';
import { TypoS, TypoXS, TypoXSBold } from '@atoms/Typos';
import { Desktop, Mobile } from '@components/Layout';
import Link from 'next/link';
import styles from './css';

type Props = {
  description?: string;
  heading: string;
  link?: {
    href: string;
    text: string;
  };
};

const SectionHeader = ({ description, heading, link }: Props): JSX.Element => (
  <div className="section-header">
    <div className="section-header-cont">
      <h2 className="section-header-heading">{heading}</h2>
      {link && (
        <TypoXSBold>
          <Link href={link.href}>{link.text}</Link>
        </TypoXSBold>
      )}
    </div>
    {description && (
      <p className="section-header-description">
        <Desktop>
          <TypoS>{description}</TypoS>
        </Desktop>
        <Mobile>
          <TypoXS>{description}</TypoXS>
        </Mobile>
      </p>
    )}
    <style jsx={true}>{styles}</style>
  </div>
);

export default SectionHeader;
