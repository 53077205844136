import COLORS from '@utils/colors';
import css from 'styled-jsx/css';

const styles = css`
  :global(.monochrome) {
    :global(.icon-bed_svg__shade) {
      fill: ${COLORS.black900};
      opacity: 0.16;
    }
  }
  :global(.no-fill) {
    :global(.icon-bed_svg__shade) {
      opacity: 0;
    }
  }
  :global(.stroke-black600) {
    :global(path) {
      stroke: ${COLORS.black600};
    }
  }
`;

export default styles;
